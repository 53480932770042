import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSalesFlowNavigation } from './salesFlowRoutingService';
import { useEffect } from 'react';
import { clearSalesOnboarding, setCountryCode, upsertAvailableCountry } from '../../store/sales/actions';
import { getAvailableCountries } from '../../store/sales/selectors';
import { userService } from '../../../../services/userService';

const SalesDispatcher = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availableCountries = useSelector(getAvailableCountries);
  const { firstPage } = useSalesFlowNavigation();

  useEffect(() => {
    dispatch(clearSalesOnboarding());

    const upsertAvailableCountries = () => {
      // availableCountries from Cognito users are stored in OAuthScreen.tsx
      userService.retrieveAvailableCountries().forEach(countryCode => dispatch(upsertAvailableCountry(countryCode)));
    };

    upsertAvailableCountries();
  }, [dispatch]);

  useEffect(() => {
    if (availableCountries && availableCountries.length > 0) {
      if (availableCountries.length === 1 && availableCountries[0] !== 'ES') {
        // all PAY-only offer creations were moved to sales-tools-web
        window.location.href = process.env.REACT_APP_SALES_TOOLS_URL + '/new-offer';
      } else {
        dispatch(setCountryCode('ES'));
      }
      navigate(firstPage());
    }
  }, [dispatch, availableCountries, navigate, firstPage]);

  return null;
};

export default SalesDispatcher;
